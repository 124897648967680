@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700;900&display=swap");
@import "~antd/dist/antd.less";
@import "react-toastify/dist/ReactToastify.css";
@import "video.js/dist/video-js.css";
@import "./../../node_modules/swiper/swiper.less";

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
main {
    font-family: "Poppins", sans-serif;
    @apply bg-primary-dark;
}

.vjs-has-started[data-is-audio="true"] .vjs-poster {
    display: block;
}

.responsive-grid-wrapper {
    @apply grid grid-cols-1 px-4 md:px-0 md:grid-cols-3 lg:grid-cols-4 gap-6 gap-y-10;
}

.shared-buttons {
    border-radius: 60px;
    @apply py-2 px-3 cursor-pointer;
}

.primary-button {
    &:extend(.shared-buttons);
    @apply bg-primary-red text-primary-white hover:bg-primary-red-hover transition-all;
    &.small {
        @apply py-1 text-xs;
    }
}

.secondary-button {
    &:extend(.shared-buttons);
    @apply bg-secondary-dark text-primary-white hover:bg-secondary-dark-hover transition-all;
    &.small {
        @apply py-1 text-xs;
    }
}

.tag-button {
    &:extend(.shared-buttons);
    @apply bg-primary-tag text-primary-white-light hover:bg-primary-tag-hover transition-all border border-primary-border hover:text-primary-red;
    &.small {
        @apply py-1 text-xs;
    }
}

.searchWrapperStiry {
    width: 200px;
    @apply rounded-full bg-primary-tag border-primary-tag outline-none;

    * {
        @apply bg-primary-tag;
    }
}

.dropdownWrapperStiry {
    width: 200px;

    * {
        @apply bg-primary-tag !important;
    }

    .ant-select-selector {
        @apply rounded-full bg-primary-tag border-primary-tag outline-none !important;
    }
}

// .dashboard-index {
//     .play-button {
//         display: none;
//     }
// }

.ff-canvas,
.ff-container,
.ff-image {
    @apply rounded-xl;
}

.min-h-screen-stiry {
    height: calc(100vh - 100px);
}

@primary-color: #EE3257;@text-color: #9AA7B4;@link-color: #EE3257;